var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customer-job-list" },
    [
      _vm.jobList.length > 0
        ? _vm._l(_vm.jobList, function (job, index) {
            return _c("div", { key: index, staticClass: "customer-job-item" }, [
              _c("div", { staticClass: "job-item-header" }, [
                _c(
                  "a",
                  {
                    staticClass: "job-title-link text-ellipsis",
                    attrs: {
                      target: "_blank",
                      title: job.name,
                      href: `/#/Job/${job.id}`,
                    },
                  },
                  [_vm._v("\n          " + _vm._s(job.name) + "\n        ")]
                ),
                _c("span", { staticClass: "job-location text-ellipsis" }, [
                  _vm._v(
                    "\n          [" + _vm._s(job.location) + "]\n        "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "job-detail-tags" },
                  [
                    _vm._l(job.activityTags, function (item) {
                      return _c(
                        "el-tooltip",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                item.id !== "StandstillJob" &&
                                item.id != "FirstOrderActivityJob",
                              expression:
                                "item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'",
                            },
                          ],
                          key: item.id,
                          attrs: {
                            effect: "light",
                            placement: "right",
                            disabled: !item.description,
                            "popper-class": "job-tag-description",
                          },
                        },
                        [
                          _c("div", {
                            attrs: { slot: "content" },
                            domProps: { innerHTML: _vm._s(item.description) },
                            slot: "content",
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "job-detail-tag",
                              class: `job-tag-${
                                _vm.jobActivityTag[item.id].theme
                              }`,
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]
                      )
                    }),
                    job.hasRecord
                      ? _c("span", {
                          staticClass: "job-record-icon",
                          attrs: { title: "该职位有职位讲解" },
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "job-item-body" }, [
                _c("div", { staticClass: "job-intro" }, [
                  _c("div", { staticClass: "job-info" }, [
                    _c("span", { staticClass: "job-info-item" }, [
                      _vm._v(
                        _vm._s(job.salary == "0K-0K" ? "面议" : job.salary)
                      ),
                    ]),
                    _c("span", { staticClass: "job-info-item" }, [
                      _vm._v(_vm._s(job.yearOfExperience)),
                    ]),
                    _c("span", { staticClass: "job-info-item" }, [
                      _vm._v(_vm._s(job.degree)),
                    ]),
                    _c("span", { staticClass: "job-info-item" }, [
                      _vm._v("需 " + _vm._s(job.recruitingCount) + " 人"),
                    ]),
                  ]),
                  _c("div", { staticClass: "job-promise" }, [
                    _vm._v("\n            佣金\n            "),
                    _c(
                      "span",
                      { staticClass: "text-orange job-commission-value" },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              job.commissionValue.indexOf("0K-0K") > -1
                                ? "按比例"
                                : job.commissionValue
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v("\n            保证期\n            "),
                    _c(
                      "span",
                      { staticClass: "text-orange job-guarantee-period" },
                      [_vm._v(_vm._s(job.guaranteePeriod))]
                    ),
                    _vm._v("\n            个月\n          "),
                  ]),
                  job.isCooperativeJob &&
                  _vm.userInfo.headhuntingFirmType > 0 &&
                  ((_vm.userInfo.headhuntingFirmType > 2 &&
                    job.headhuntingFirmCommissionValue > 0) ||
                    (_vm.userInfo.headhuntingFirmType < 3 &&
                      job.otherFirmCommissionValue > 0))
                    ? _c(
                        "div",
                        { staticClass: "job-firm-commission" },
                        [
                          _vm._v("\n            分佣比例\n            "),
                          _c("span", { staticClass: "text-grey" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.userInfo.headhuntingFirmType > 2
                                    ? job.headhuntingFirmCommissionValue + "%"
                                    : job.otherFirmCommissionValue + "%"
                                ) +
                                "\n            "
                            ),
                          ]),
                          _vm.userInfo.headhuntingFirmType < 3
                            ? _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    "popper-class": "commission-rate-text",
                                    effect: "light",
                                    content:
                                      "简历提供者，分成比例需要和平台运营确认。",
                                    placement: "right",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tip-icon" },
                                    [
                                      _c("font-icon", {
                                        staticClass: "default",
                                        attrs: { href: "#icon-talent_qd" },
                                      }),
                                      _c("font-icon", {
                                        staticClass: "hover",
                                        attrs: { href: "#icon-talent_ql" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "job-number" }, [
                  job.isMyJob
                    ? _c("span", { attrs: { title: "抢单" } }, [
                        _vm._v("\n            抢\n            "),
                        _c(
                          "span",
                          {
                            staticClass: "job-number-detail",
                            on: {
                              click: function ($event) {
                                return _vm.goToJobDetail(job)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(job.orderCount) +
                                "\n            "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c("span", { attrs: { title: "推荐" } }, [
                    _vm._v("\n            推\n            "),
                    _c(
                      "span",
                      {
                        staticClass: "job-number-detail",
                        on: {
                          click: function ($event) {
                            return _vm.goToFloatList(job, "total")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              job.recommendationStatistic
                                ? job.recommendationStatistic.total
                                : 0
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _c("span", { attrs: { title: "面试" } }, [
                    _vm._v("\n            面\n            "),
                    _c(
                      "span",
                      {
                        staticClass: "job-number-detail",
                        on: {
                          click: function ($event) {
                            return _vm.goToFloatList(job, "interviewCount")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              job.recommendationStatistic
                                ? job.recommendationStatistic.interviewCount
                                : 0
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _c("span", { attrs: { title: "offer" } }, [
                    _vm._v("\n            offer\n            "),
                    _c(
                      "span",
                      {
                        staticClass: "job-number-detail",
                        on: {
                          click: function ($event) {
                            return _vm.goToFloatList(job, "offerCount")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              job.recommendationStatistic
                                ? job.recommendationStatistic.offerCount
                                : 0
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "job-publish" },
                  [
                    _c("avatar", {
                      staticClass: "user-avatar",
                      attrs: {
                        size: "sm",
                        src: job.avatarUrl,
                        userId: job.ownerId,
                        enableCard: true,
                        enableLink: true,
                        shareData: _vm.getShareData(job),
                      },
                    }),
                    _c("span", { staticClass: "job-date" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm._f("dateFilter")(job.createDateTime)) +
                          " 发布\n          "
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "job-grab-btn-wrapper" },
                  [
                    job.status === 1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "job-grab-btn",
                            attrs: {
                              icon: "el-icon-time",
                              type: "primary",
                              loading: job.grabing,
                              disabled: job.isMyOrder || job.isMyJob,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.grabOrder(job)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  !job.isMyOrder && !job.isMyJob
                                    ? "抢单"
                                    : "已抢"
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ])
          })
        : _c("div", { staticClass: "empty-list" }, [
            _c("span", { staticClass: "empty-img" }),
            _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
          ]),
      _vm.total > _vm.pager.take
        ? _c(
            "el-pagination",
            {
              staticClass: "job-list-pagination",
              attrs: {
                "current-page": _vm.pager.current,
                "page-sizes": [10, 30, 50],
                "page-size": _vm.pager.take,
                layout: "prev, pager, next, slot, total, sizes",
                total: _vm.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            },
            [
              _c("span", { staticClass: "pagination-text" }, [
                _c(
                  "span",
                  [
                    _vm._v("\n        前往\n        "),
                    _c("el-input", {
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handlePagerJump(_vm.pagerJump)
                        },
                      },
                      model: {
                        value: _vm.pagerJump,
                        callback: function ($$v) {
                          _vm.pagerJump = $$v
                        },
                        expression: "pagerJump",
                      },
                    }),
                    _vm._v("\n        页\n      "),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.handlePagerJump(_vm.pagerJump)
                      },
                    },
                  },
                  [_vm._v("\n        跳转\n      ")]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c("grab-result-dialog", {
        ref: "grabResultDialog",
        on: {
          "recommend-btn-callback": _vm.handleRecommendBtnCallback,
          "grab-dialog-close": _vm.grabDialogClose,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }