var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "customer-project" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.departmentLoading,
            expression: "departmentLoading",
          },
        ],
        staticClass: "customer-sub-customer",
      },
      [
        _c(
          "ul",
          { staticClass: "sub-customer-list" },
          _vm._l(_vm.departmentList, function (department) {
            return _c(
              "li",
              {
                key: department.departmentId,
                staticClass: "sub-customer-item",
                class: {
                  active: department.departmentId === _vm.params.departmentId,
                },
                on: {
                  click: function ($event) {
                    return _vm.getDepJobOrGradeList(department.departmentId)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(department.name) +
                    "(" +
                    _vm._s(department.count) +
                    ")\n            "
                ),
              ]
            )
          }),
          0
        ),
        _c(
          "a",
          {
            staticClass: "text-operate-btn grey department-link",
            attrs: {
              target: "_blank",
              href: `/#/Customer/${_vm.customerId}/department`,
            },
          },
          [_vm._v("\n            组织架构\n        ")]
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "project-wrap" },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.projectListLoading,
                expression: "projectListLoading",
              },
            ],
            staticClass: "project-list",
          },
          [
            _vm.projectList.length > 0
              ? _vm._l(_vm.projectList, function (project, index) {
                  return _c("project-item", {
                    key: project.id,
                    ref: "project_" + index,
                    refInFor: true,
                    attrs: { project: project, projectIndex: index },
                  })
                })
              : _c("div", { staticClass: "empty" }, [
                  _c("span", { staticClass: "empty-img" }),
                  _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
                ]),
          ],
          2
        ),
        _vm.projectList.length > 0
          ? _c(
              "el-pagination",
              {
                staticClass: "project-list-pagination",
                attrs: {
                  "current-page": _vm.pager.current,
                  "page-sizes": [20, 30, 50],
                  "page-size": _vm.pager.size,
                  layout: "prev, pager, next, slot, total, sizes",
                  total: _vm.pager.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              },
              [
                _c("span", { staticClass: "pagination-text" }, [
                  _c(
                    "span",
                    [
                      _vm._v(
                        "\n                    前往\n                    "
                      ),
                      _c("el-input", {
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handlePagerJump(_vm.pagerJump)
                          },
                        },
                        model: {
                          value: _vm.pagerJump,
                          callback: function ($$v) {
                            _vm.pagerJump = $$v
                          },
                          expression: "pagerJump",
                        },
                      }),
                      _vm._v("\n                    页\n                "),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handlePagerJump(_vm.pagerJump)
                        },
                      },
                    },
                    [_vm._v(" 跳转 ")]
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }