var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "customer-data-info" }, [
    _c("div", { staticClass: "customer-job-delivery" }, [
      _c(
        "div",
        { staticClass: "job-delivery-title-box" },
        [
          _c("font-icon", {
            staticClass: "job-delivery-icon",
            attrs: { href: "#icon-smile" },
          }),
          _c("span", { staticClass: "job-delivery-title" }, [
            _vm._v("职位交付情况"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "job-delivery-content" }, [
        _c("div", { staticClass: "job-delivery-item" }, [
          _c("span", { staticClass: "job-delivery-item-count" }, [
            _vm._v(_vm._s(_vm.statisticsInfo.titleCount)),
          ]),
          _c("span", { staticClass: "job-delivery-item-title" }, [
            _vm._v("职位总数"),
          ]),
        ]),
        _c("div", { staticClass: "job-delivery-item" }, [
          _c("span", { staticClass: "job-delivery-item-count" }, [
            _vm._v(_vm._s(_vm.statisticsInfo.recommendTotal)),
          ]),
          _c("span", { staticClass: "job-delivery-item-title" }, [
            _vm._v("推荐总数"),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "data-info-right" }, [
      _c("div", { staticClass: "data-info-list customer-recommend-info" }, [
        _c(
          "div",
          { staticClass: "data-info-title" },
          [
            _c("font-icon", {
              staticClass: "data-info-right-icon",
              attrs: { href: "#icon-recommender" },
            }),
            _vm._v("\n        推荐情况\n      "),
          ],
          1
        ),
        _c("div", { staticClass: "data-info-item" }, [
          _c("span", { staticClass: "data-info-item-count" }, [
            _vm._v(_vm._s(_vm.statisticsInfo.zeroPushCount)),
          ]),
          _c("span", { staticClass: "data-info-item-title" }, [
            _vm._v("0推职位"),
          ]),
        ]),
        _c("div", { staticClass: "data-info-item" }, [
          _c("span", { staticClass: "data-info-item-count" }, [
            _vm._v(_vm._s(_vm.statisticsInfo.onePushCount)),
          ]),
          _c("span", { staticClass: "data-info-item-title" }, [
            _vm._v("1推职位"),
          ]),
        ]),
        _c("div", { staticClass: "data-info-item" }, [
          _c("span", { staticClass: "data-info-item-count" }, [
            _vm._v(_vm._s(_vm.statisticsInfo.twoToFourPushCount)),
          ]),
          _c("span", { staticClass: "data-info-item-title" }, [
            _vm._v("2-4推职位"),
          ]),
        ]),
        _c("div", { staticClass: "data-info-item" }, [
          _c("span", { staticClass: "data-info-item-count" }, [
            _vm._v(_vm._s(_vm.statisticsInfo.fivePlusPushCount)),
          ]),
          _c("span", { staticClass: "data-info-item-title" }, [
            _vm._v("5+推职位"),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "data-info-list customer-recommend-info" }, [
        _c(
          "div",
          { staticClass: "data-info-title" },
          [
            _c("font-icon", {
              staticClass: "data-info-right-icon",
              attrs: { href: "#icon-conversion-funnel" },
            }),
            _vm._v("\n        转化漏斗\n      "),
          ],
          1
        ),
        _c("div", { staticClass: "data-info-item" }, [
          _c("span", { staticClass: "data-info-item-count" }, [
            _vm._v(_vm._s(_vm.statisticsInfo.recommendTotal)),
          ]),
          _c("span", { staticClass: "data-info-item-title" }, [_vm._v("推荐")]),
        ]),
        _c("div", { staticClass: "data-info-item" }, [
          _c("span", { staticClass: "data-info-item-count" }, [
            _vm._v(_vm._s(_vm.statisticsInfo.interviewCount)),
          ]),
          _c("span", { staticClass: "data-info-item-title" }, [_vm._v("面试")]),
        ]),
        _c("div", { staticClass: "data-info-item" }, [
          _c("span", { staticClass: "data-info-item-count" }, [
            _vm._v(_vm._s(_vm.statisticsInfo.offerCount)),
          ]),
          _c("span", { staticClass: "data-info-item-title" }, [
            _vm._v("Offer"),
          ]),
        ]),
        _c("div", { staticClass: "data-info-item" }, [
          _c("span", { staticClass: "data-info-item-count" }, [
            _vm._v(_vm._s(_vm.statisticsInfo.onBoardCount)),
          ]),
          _c("span", { staticClass: "data-info-item-title" }, [_vm._v("入职")]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }