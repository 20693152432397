var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customer-job-list" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.tabs, function (tab, index) {
          return _c("el-tab-pane", {
            key: "tab_" + index,
            attrs: { label: tab.label, name: tab.name },
          })
        }),
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName == "job",
              expression: "activeName == 'job'",
            },
          ],
          staticClass: "job-list",
        },
        [
          _c("customer-data-info", {
            ref: "cusDataInfo",
            attrs: { customerId: _vm.customerId },
          }),
          _c("customer-detail-bottom", {
            ref: "cusDetailBottom",
            attrs: {
              customerName: _vm.customerName,
              customerId: _vm.customerId,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName == "project",
              expression: "activeName == 'project'",
            },
          ],
        },
        [
          _c("customer-project", {
            ref: "customerProject",
            attrs: {
              customerName: _vm.customerName,
              customerId: _vm.customerId,
              activeName: _vm.activeName,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }