<template>
  <div class="customer-data-info">
    <div class="customer-job-delivery">
      <div class="job-delivery-title-box">
        <font-icon class="job-delivery-icon" href="#icon-smile"></font-icon>
        <span class="job-delivery-title">职位交付情况</span>
      </div>
      <div class="job-delivery-content">
        <div class="job-delivery-item">
          <span class="job-delivery-item-count">{{statisticsInfo.titleCount}}</span>
          <span class="job-delivery-item-title">职位总数</span>
        </div>
        <div class="job-delivery-item">
          <span class="job-delivery-item-count">{{statisticsInfo.recommendTotal}}</span>
          <span class="job-delivery-item-title">推荐总数</span>
        </div>
      </div>
    </div>
    <div class="data-info-right">
      <div class="data-info-list customer-recommend-info">
        <div class="data-info-title">
          <font-icon class="data-info-right-icon" href="#icon-recommender"></font-icon>
          推荐情况
        </div>
        <div class="data-info-item">
          <span class="data-info-item-count">{{statisticsInfo.zeroPushCount}}</span>
          <span class="data-info-item-title">0推职位</span>
        </div>
        <div class="data-info-item">
          <span class="data-info-item-count">{{statisticsInfo.onePushCount}}</span>
          <span class="data-info-item-title">1推职位</span>
        </div>
        <div class="data-info-item">
          <span class="data-info-item-count">{{statisticsInfo.twoToFourPushCount}}</span>
          <span class="data-info-item-title">2-4推职位</span>
        </div>
        <div class="data-info-item">
          <span class="data-info-item-count">{{statisticsInfo.fivePlusPushCount}}</span>
          <span class="data-info-item-title">5+推职位</span>
        </div>
      </div>
      <div class="data-info-list customer-recommend-info">
        <div class="data-info-title">
          <font-icon class="data-info-right-icon" href="#icon-conversion-funnel"></font-icon>
          转化漏斗
        </div>
        <div class="data-info-item">
          <span class="data-info-item-count">{{statisticsInfo.recommendTotal}}</span>
          <span class="data-info-item-title">推荐</span>
        </div>
        <div class="data-info-item">
          <span class="data-info-item-count">{{statisticsInfo.interviewCount}}</span>
          <span class="data-info-item-title">面试</span>
        </div>
        <div class="data-info-item">
          <span class="data-info-item-count">{{statisticsInfo.offerCount}}</span>
          <span class="data-info-item-title">Offer</span>
        </div>
        <div class="data-info-item">
          <span class="data-info-item-count">{{statisticsInfo.onBoardCount}}</span>
          <span class="data-info-item-title">入职</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerService from '#/js/service/customerService.js';
export default {
  name: "customerDataInfo",
  props: {
    customerId: String
  },
  data() {
    return {
      statisticsInfo: {
        fivePlusPushCount: 0,
        interviewCount: 0,
        offerCount: 0,
        onBoardCount: 0,
        onePushCount: 0,
        recommendTotal: 0,
        titleCount: 0,
        twoToFourPushCount: 0,
        zeroPushCount: 0
      }
    }
  },
  mounted() {
    this.getStatisticsInfo();
  },
  methods: {
    getStatisticsInfo() {
      CustomerService.getCustomerStatisticsInfo({
        customerId: this.customerId
      }).then(res => {
        this.statisticsInfo = res;
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scope>
@media screen and (max-width: 1400px) {
  .customer-location {
    max-width: 400px;
  }
  .customer-job-delivery {
    width: 436px !important;
  }
  .data-info-right {
    width: calc(100% - 456px) !important;
  }
  .customer-detail-bottom {
    .customer-sub-customer {
      width: 310px !important;
    }
    .customer-job-grade-list {
      width: calc(100% - 330px) !important;
    }
  }
}
@media screen and (min-width: 1400px) and (max-width: 1680px) {
  .customer-location {
    max-width: 650px;
  }
  .customer-job-delivery {
    width: 536px !important;
  }
  .data-info-right {
    width: calc(100% - 556px) !important;
  }
  .customer-detail-bottom {
    .customer-sub-customer {
      width: 360px !important;
    }
    .customer-job-grade-list {
      width: calc(100% - 380px) !important;
    }
  }
}
  .customer-data-info {
    height: 180px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .customer-job-delivery {
      // width: 470px;
      width: 600px;
      height: 100%;
      padding: 0 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f8f8f8;
      .job-delivery-title-box {
        width: 50%;
        .job-delivery-icon {
          width: 50px;
          height: 50px;
          margin-right: 15px;
          vertical-align: middle;
        }
        .job-delivery-title {
          display: inline-block;
          font-size: 18px;
          color: #666;
          width: 135px;
        }
      }
      .job-delivery-content {
        width: 50%;
        .job-delivery-item {
          width: 114px;
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          .job-delivery-item-count {
            color: #666;
            font-size: 30px;
            line-height: 38px;
          }
          .job-delivery-item-title {
            color: #9b9b9b;
            font-size: 16px;
            line-height: 22px;
          }
          & + .job-delivery-item {
            position: relative;
            &::before {
              content: "";
              display: block;
              width: 1px;
              height: 30px;
              background-color: #e9e9e9;
              position: absolute;
              left: 0;
              top: 15px;
            }
          }
        }
      }
    }
    .data-info-right {
      // width: 710px;
      width: calc(100% - 620px);
      height: 100%;
      margin-left: 20px;
      .data-info-list {
        display: flex;
        height: 86px;
        .data-info-title {
          width: 86px;
          background-color: $primary;
          color: #fff;
          .data-info-right-icon {
            width: 40px;
            height: 40px;
          }
        }
        .data-info-item {
          // width: 232px;
          width: 25%;
          background-color: #f8f8f8;
          color: #666;
          .data-info-item-count {
            font-size: 26px;
            line-height: 36px;
          }
          .data-info-item-title {
            font-size: 14px;
            color: #9b9b9b;
            line-height: 20px;
          }
          & + .data-info-item {
            margin-left: 10px;
            position: relative;
            &::before {
              content: "";
              display: block;
              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-left: 10px solid #f8f8f8;
              border-bottom: 5px solid transparent;
              position: absolute;
              left: -10px;
              top: 40px;
            }
            &::after {
              content: "";
              display: block;
              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-left: 10px solid #fff;
              border-bottom: 5px solid transparent;
              position: absolute;
              left: 0;
              top: 40px;
            }
          }
        }
        .data-info-title, .data-info-item {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        & + .data-info-list {
          margin-top: 8px;
        }
      }
    }
  }
</style>