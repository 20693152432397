<template>
  <div class="customer-detail-bottom">
    <div
      class="customer-sub-customer"
      v-loading="departmentLoading">
      <ul class="sub-customer-list">
        <li
          class="sub-customer-item"
          :class="{'active': department.departmentId === params.departmentId}"
          v-for="department in departmentList"
          :key="department.departmentId"
          :title="department.name+`(${department.count})`"
          @click="getDepJobOrGradeList(department.departmentId)">
          {{department.name}}({{department.count}})
        </li>
      </ul>
      <!-- :href="`/Headhunting/MyCompany.html#/Customer/${customerId}/department`" -->
      <a
        class="text-operate-btn grey department-link"
        target="_blank"
        :href="`/Headhunting/MyCompany.html#/Customer/${customerId}/department`">
        组织架构
      </a>
    </div>
    <div class="customer-job-grade-list">
      <div class="customer-list-tab">
        <span
          class="customer-list-tab-item"
          :class="{'active': params.status === 1}"
          @click="setStatus(1)">
          招聘中
          <span class="text-primary">({{totalRecruitingCount}})</span>
        </span>
        <span
          class="customer-list-tab-item"
          :class="{'active': params.status === 2}"
          @click="setStatus(2)">
          已暂停
          <span class="text-primary">({{totalPausedCount}})</span>
        </span>
        <span
          class="customer-list-tab-item"
          :class="{'active': params.status === 4}"
          @click="setStatus(4)">
          已完成
          <span class="text-primary">({{totalFinishedCount}})</span>
        </span>
        <!-- <span
          class="customer-list-tab-item"
          :class="{'active': params.status === 6}"
          @click="setStatus(6)"
          v-if="userInfo.isBindingPinganAccount && pinganAccountInfoGetted">
          {{userInfo.isBindingPingnAccountAndJobDistributor ? '分配职位' : '创建职位'}}
        </span> -->
        <span
          class="customer-list-tab-item"
          :class="{'active': params.status === 5}"
          @click="setStatus(5)">
          职级情况
        </span>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          class="add-customer-grade-btn"
          @click="editCustomerGrade(false)"
          v-if="params.status === 5">
          新增职级
        </el-button>
      </div>
      <div class="customer-list-wrapper" v-loading="listLoading">
        <template v-if="params.status < 5">
          <customer-job-list
            ref="customerJobList"
            :jobList="dataList"
            :total="dataListTotal"
            @update-list="getNormalJobList(true)"
          ></customer-job-list>
        </template>
        <template v-if="params.status === 5">
          <customer-grade-list
            ref="customerGradeList"
            :gradeList="dataList"
            :total="dataListTotal"
            @edit-grade="(grade) => editCustomerGrade(true, grade)"
            @update-list="(silent) => getGradeList(silent)"
          ></customer-grade-list>
        </template>
      </div>
    </div>
    <cus-grade-edit-dialog
      ref="cusGradeEditDialog"
      :customerId="customerId"
      :customerName="customerName"
      @update-list="getGradeList(false)"
    ></cus-grade-edit-dialog>
  </div>
</template>

<script>
import CusGradeEditDialog from '../component/cus-grade-edit-dialog.vue';
import CustomerJobList from '../component/customer-job-list.vue';
import CustomerGradeList from '../component/customer-grade-list.vue';
import CustomerService from '#/js/service/customerService.js';
export default {
  name: "customerDetailBottom",
  props: {
    customerId: String,
    customerName: String
  },
  components: {
    CusGradeEditDialog,
    CustomerJobList,
    CustomerGradeList,
  },
  data() {
    return {
      departmentLoading: false,
      departmentList: [],
      listLoading: false,
      dataList: [],
      totalRecruitingCount: 0,
      totalPausedCount: 0,
      totalFinishedCount: 0,
      dataListTotal: 0,
      // pinganAccountInfoGetted: false,
      params: {
        customerId: "",
        departmentId: 0,
        status: 1
      },
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    }
  },
  mounted() {
    this.params.customerId = this.customerId;
    this.getDepartmentList();
    this.getListData();
    if(this.$route.query.tabs === 'grade') {
      this.params.status = 5;
      this.$nextTick(() => {
        this.getGradeList(true);
      })
    }
    // this.getPinganAccountInfo(() => {
    //   if(window.location.href.indexOf('#jobOperation') > -1) {
    //     this.params.status = 6;
    //     this.getListData();
    //   }
    // });
  },
  methods: {
    setStatus(status) {
      this.params.status = status;
      this.getListData();
    },
    getListData() {
      this.$nextTick(() => {
        switch(this.params.status) {
          case 5:
            this.getGradeList(false);
            break;
          // case 6:
          //   this.getOpenMarketJobList();
          //   break;
          default:
            this.getNormalJobList(false);
        }
      })
    },
    getNormalJobList(isSilent) {
      this.listLoading = true;
      let params = Object.assign({}, this.params),
        pager = this.$refs.customerJobList.pager;
      if(!isSilent) {
        pager.current = 1;
      }
      params.start = (pager.current - 1) * pager.take;
      params.take = pager.take;
      CustomerService.getCustomerJobList(params)
      .then(res => {
        this.listLoading = false;
        this.totalRecruitingCount = res.totalRecruitingCount;
        this.totalPausedCount = res.totalPausedCount;
        this.totalFinishedCount = res.totalFinishedCount;
        this.dataListTotal = res.total;
        this.dataList = res.list;
        if(isSilent) {
          this.scrollToList();
        }
      }).catch(err => {
        this.listLoading = false;
      })
    },
    getGradeList(isSilent) {
      this.listLoading = true;
      let params = Object.assign({}, this.params),
          pager = this.$refs.customerGradeList.pager;
      if(!isSilent) {
        pager.current = 1;
      }
      params.start = (pager.current - 1) * pager.take;
      params.take = pager.take;
      CustomerService.getCustomerGradeList(params)
      .then(res => {
        this.listLoading = false;
        this.dataListTotal = res.total;
        this.dataList = res.list;
        if(isSilent) {
          this.scrollToList();
        }
      }).catch(err => {
        this.listLoading = false;
      })
    },
    scrollToList() {
      this.$nextTick(() => {
        // document.documentElement.scrollTop = document.querySelector(".customer-list-wrapper").offsetTop;
        document.querySelector('.customer-detail').scrollTop = document.querySelector(".customer-list-wrapper").offsetTop;
      })
    },
    getDepartmentList() {
      this.departmentLoading = true;
      CustomerService.getDepStatisticsInfo({
        customerId: this.customerId
      }).then(res => {
        this.departmentLoading = false;
        this.departmentList = res;
      }).catch(err => {
        this.departmentLoading = false;
      })
    },
    getDepJobOrGradeList(id) {
      this.params.departmentId = id;
      if(this.params.status !== 5) {
        this.getListData();
      }
    },
    // getPinganAccountInfo(cb) {
    //   _request({
    //     url: '/Firm/IsAppointedCustomer',
    //     method: "GET",
    //     data: {
    //       customerId: this.customerId
    //     },
    //     throwBusinessError: true
    //   }).then(res => {
    //     this.pinganAccountInfoGetted = true;
    //     cb && cb();
    //   }).catch(err => {
    //     if(err && err.message) {
    //       console.log(err.message)
    //     }
    //   })
    // },
    editCustomerGrade(isEdit, json) {
      this.$refs.cusGradeEditDialog.show(isEdit, json);
    },
  }
}
</script>

<style lang="scss" scope>
  .customer-detail-bottom {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .customer-sub-customer {
      // width: 280px;
      width: 410px;
      margin-right: 20px;
      padding: 30px;
      background-color: #f8f8f8;
      position: relative;
      .sub-customer-list {
        .sub-customer-item {
          color: #333;
          line-height: 36px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          &.active {
            color: $primary;
          }
          &:hover {
            color: $primary;
          }
        }
      }
      .department-link {
        position: absolute;
        top: 20px;
        right: 20px;
        line-height: 36px;
        z-index: 1;
      }
    }
    .customer-job-grade-list {
      // width: 892px;
      width: calc(100% - 430px);
      .customer-list-tab {
        height: 36px;
        line-height: 36px;
        &::before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 18px;
          background-color: $primary;
          top: 4px;
          position: relative;
          margin-right: 9px;
        }
        &-item {
          font-size: 14px;
          margin-right: 15px;
          color: #9b9b9b;
          cursor: pointer;
          &.active {
            color: $primary;
          }
        }
        .add-customer-grade-btn {
          float: right;
          height: 36px;
          line-height: 36px;
        }
      }
    }
  }
</style>