var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customer-detail-bottom" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.departmentLoading,
              expression: "departmentLoading",
            },
          ],
          staticClass: "customer-sub-customer",
        },
        [
          _c(
            "ul",
            { staticClass: "sub-customer-list" },
            _vm._l(_vm.departmentList, function (department) {
              return _c(
                "li",
                {
                  key: department.departmentId,
                  staticClass: "sub-customer-item",
                  class: {
                    active: department.departmentId === _vm.params.departmentId,
                  },
                  attrs: { title: department.name + `(${department.count})` },
                  on: {
                    click: function ($event) {
                      return _vm.getDepJobOrGradeList(department.departmentId)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(department.name) +
                      "(" +
                      _vm._s(department.count) +
                      ")\n      "
                  ),
                ]
              )
            }),
            0
          ),
          _c(
            "a",
            {
              staticClass: "text-operate-btn grey department-link",
              attrs: {
                target: "_blank",
                href: `/Headhunting/MyCompany.html#/Customer/${_vm.customerId}/department`,
              },
            },
            [_vm._v("\n      组织架构\n    ")]
          ),
        ]
      ),
      _c("div", { staticClass: "customer-job-grade-list" }, [
        _c(
          "div",
          { staticClass: "customer-list-tab" },
          [
            _c(
              "span",
              {
                staticClass: "customer-list-tab-item",
                class: { active: _vm.params.status === 1 },
                on: {
                  click: function ($event) {
                    return _vm.setStatus(1)
                  },
                },
              },
              [
                _vm._v("\n        招聘中\n        "),
                _c("span", { staticClass: "text-primary" }, [
                  _vm._v("(" + _vm._s(_vm.totalRecruitingCount) + ")"),
                ]),
              ]
            ),
            _c(
              "span",
              {
                staticClass: "customer-list-tab-item",
                class: { active: _vm.params.status === 2 },
                on: {
                  click: function ($event) {
                    return _vm.setStatus(2)
                  },
                },
              },
              [
                _vm._v("\n        已暂停\n        "),
                _c("span", { staticClass: "text-primary" }, [
                  _vm._v("(" + _vm._s(_vm.totalPausedCount) + ")"),
                ]),
              ]
            ),
            _c(
              "span",
              {
                staticClass: "customer-list-tab-item",
                class: { active: _vm.params.status === 4 },
                on: {
                  click: function ($event) {
                    return _vm.setStatus(4)
                  },
                },
              },
              [
                _vm._v("\n        已完成\n        "),
                _c("span", { staticClass: "text-primary" }, [
                  _vm._v("(" + _vm._s(_vm.totalFinishedCount) + ")"),
                ]),
              ]
            ),
            _c(
              "span",
              {
                staticClass: "customer-list-tab-item",
                class: { active: _vm.params.status === 5 },
                on: {
                  click: function ($event) {
                    return _vm.setStatus(5)
                  },
                },
              },
              [_vm._v("\n        职级情况\n      ")]
            ),
            _vm.params.status === 5
              ? _c(
                  "el-button",
                  {
                    staticClass: "add-customer-grade-btn",
                    attrs: {
                      type: "primary",
                      icon: "el-icon-circle-plus-outline",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.editCustomerGrade(false)
                      },
                    },
                  },
                  [_vm._v("\n        新增职级\n      ")]
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticClass: "customer-list-wrapper",
          },
          [
            _vm.params.status < 5
              ? [
                  _c("customer-job-list", {
                    ref: "customerJobList",
                    attrs: { jobList: _vm.dataList, total: _vm.dataListTotal },
                    on: {
                      "update-list": function ($event) {
                        return _vm.getNormalJobList(true)
                      },
                    },
                  }),
                ]
              : _vm._e(),
            _vm.params.status === 5
              ? [
                  _c("customer-grade-list", {
                    ref: "customerGradeList",
                    attrs: {
                      gradeList: _vm.dataList,
                      total: _vm.dataListTotal,
                    },
                    on: {
                      "edit-grade": (grade) =>
                        _vm.editCustomerGrade(true, grade),
                      "update-list": (silent) => _vm.getGradeList(silent),
                    },
                  }),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c("cus-grade-edit-dialog", {
        ref: "cusGradeEditDialog",
        attrs: { customerId: _vm.customerId, customerName: _vm.customerName },
        on: {
          "update-list": function ($event) {
            return _vm.getGradeList(false)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }