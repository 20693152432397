<template>
    <div class="customer-job-list">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <!-- <el-tab-pane label="用户管理" name="first">客户职位</el-tab-pane>
            <el-tab-pane label="配置管理" name="second">客户项目</el-tab-pane> -->
            <el-tab-pane
                v-for="(tab, index) in tabs"
                :key="'tab_' + index"
                :label="tab.label"
                :name="tab.name"
            >
            </el-tab-pane>
        </el-tabs>
        <div class="job-list" v-show="activeName == 'job'">
            <customer-data-info
                ref="cusDataInfo"
                :customerId="customerId"
            ></customer-data-info>
            <customer-detail-bottom
                ref="cusDetailBottom"
                :customerName="customerName"
                :customerId="customerId"
            ></customer-detail-bottom>
        </div>
        <div v-show="activeName == 'project'">
            <customer-project
                ref="customerProject"
                :customerName="customerName"
                :customerId="customerId"
                :activeName="activeName"
            >
            </customer-project>
        </div>
    </div>
</template>

<script>
import CustomerDataInfo from "./customer-data-info.vue";
import CustomerDetailBottom from "./customer-detail-bottom.vue";
import CustomerProject from "./customer-project.vue";

export default {
    props: {
        customerId: {
            type: String,
            default: "",
        },
        customerName: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            activeName: "job",
            tabs: [
                { label: "客户职位", name: "job" },
                { label: "客户项目", name: "project" },
            ],
        };
    },
    components: {
        CustomerDataInfo,
        CustomerDetailBottom,
        CustomerProject,
    },
    methods: {
        handleClick(tab, event) {
            // console.log(tab.label,tab.name);
            // console.log(this.activeName);
        },
    },
};
</script>

<style lang="scss" scoped>
.customer-job-list {
    width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 8px;
    .job-list {
        padding: 0 20px;
    }
    /deep/ .el-tabs__nav.is-top {
        margin: 0 45px;
    }
    /deep/ .el-tabs__header {
        padding-top: 20px;
    }
}
</style>