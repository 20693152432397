<template>
    <div class="customer-project">
        <div class="customer-sub-customer" v-loading="departmentLoading">
            <ul class="sub-customer-list">
                <li
                    class="sub-customer-item"
                    :class="{
                        active: department.departmentId === params.departmentId,
                    }"
                    v-for="department in departmentList"
                    :key="department.departmentId"
                    @click="getDepJobOrGradeList(department.departmentId)"
                >
                    {{ department.name }}({{ department.count }})
                </li>
            </ul>
            <!-- :href="`/Headhunting/MyCompany.html#/Customer/${customerId}/department`" -->
            <a
                class="text-operate-btn grey department-link"
                target="_blank"
                :href="`/#/Customer/${customerId}/department`"
            >
                组织架构
            </a>
        </div>

        <div class="project-wrap">
            <!-- 项目列表 -->
            <div class="project-list" v-loading="projectListLoading">
                <template v-if="projectList.length > 0">
                    <project-item
                        :project="project"
                        :projectIndex="index"
                        v-for="(project, index) in projectList"
                        :key="project.id"
                        :ref="'project_' + index"
                    ></project-item>
                </template>
                <div class="empty" v-else>
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div>
            </div>
            <!-- 分页 -->
            <el-pagination
                :current-page="pager.current"
                :page-sizes="[20, 30, 50]"
                :page-size="pager.size"
                class="project-list-pagination"
                layout="prev, pager, next, slot, total, sizes"
                :total="pager.total"
                v-if="projectList.length > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
                <span class="pagination-text">
                    <span>
                        前往
                        <el-input
                            v-model="pagerJump"
                            @keyup.enter.native="handlePagerJump(pagerJump)"
                        ></el-input>
                        页
                    </span>
                    <span @click="handlePagerJump(pagerJump)"> 跳转 </span>
                </span>
            </el-pagination>
        </div>
    </div>
</template>

<script>
import ProjectItem from "../../project/component/project-item.vue";
import CustomerService from "#/js/service/customerService.js";
import ProjectService from "#/js/service/projectService.js";

export default {
    name: "customer-project",
    components: {
        ProjectItem,
    },
    props: {
        customerId: String,
        customerName: String,
        activeName: String,
    },
    data() {
        return {
            departmentLoading: false,
            departmentList: [],
            params: {
                customerId: "",
                departmentId: 0,
                status: 1,
            },

            projectListLoading: false,
            projectList: [],
            pager: {
                current: 1,
                size: 20,
                total: 0,
            },
            pagerJump: 0,
        };
    },
    created() {
        // this.getDepartmentList();
        // this.search();

        // console.log(this.activeName);
        if (this.activeName) {
            this.getDepartmentList(this.activeName);
        }
        this.getProjectByDepartment();
    },
    mounted() {},
    watch: {
        activeName(val, oldVal) {
            // console.log(val, oldVal);
            this.getDepartmentList(val);
            // this.getProjectByDepartment();
            // if (val == "project") {
            //     this.getProjectByDepartment();
            // }
        },
    },
    methods: {
        // 获取客户职位/项目统计数据
        getDepartmentList(type) {
            // console.log(type);
            this.departmentLoading = true;
            CustomerService.getDepStatisticsInfo({
                customerId: this.customerId,
                type: type,
            })
                .then((res) => {
                    this.departmentList = res || [];
                })
                .finally(() => {
                    this.departmentLoading = false;
                });
        },
        // 点击左侧的客户部门
        getDepJobOrGradeList(id) {
            this.params.departmentId = id;
            if (this.params.status !== 5) {
                // this.search();
                this.getProjectByDepartment();
            }
        },

        // 重新获取列表，定位到列表的第一条数据
        scrollToList() {
            this.$nextTick(() => {
                document.documentElement.scrollTop = document.querySelector(
                    ".project-list"
                ).offsetTop;
            });
        },

        // 通过部门ID获取项目列表
        getProjectByDepartment(silent) {
            if (!silent) {
                this.pager.current = 1;
            }
            const params = {
                customerId: this.customerId,
                departmentId: this.params.departmentId,
                take: this.pager.size,
                start: (this.pager.current - 1) * this.pager.size,
            };
            this.projectListLoading = true;
            ProjectService.getProjectByDepartment(params)
                .then((res) => {
                    // console.log(res);
                    this.pager.total = res.total;
                    this.projectList = res.list;

                    // document.documentElement.scrollTop = 0;
                    this.scrollToList();
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.projectListLoading = false;
                });
        },

        // search(silent) {
        //     if (!silent) {
        //         this.pager.current = 1;
        //     }
        //     const params = {
        //         creatorIds: [],
        //         customerIds: [],
        //         endDateTime: "",
        //         startDateTime: "",
        //         jobSeries: [],
        //         locations: [],
        //         keywords: "",
        //         take: this.pager.size,
        //         start: (this.pager.current - 1) * this.pager.size,
        //     };

        //     this.projectListLoading = true;
        //     ProjectService.getProjectList(params)
        //         .then((res) => {
        //             this.pager.total = res.total;
        //             this.projectList = res.list;
        //             this.resultFilterMap = res.categoryGroupedItemsDic;

        //             document.documentElement.scrollTop = 0;
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //         })
        //         .finally(() => {
        //             this.projectListLoading = false;
        //         });
        // },
        // 分页
        handleSizeChange(val) {
            this.pager.size = val;
            this.pager.current = 1;
            // this.search(true);
            this.getProjectByDepartment(true);
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            // this.search(true);
            this.getProjectByDepartment(true);
        },
        handlePagerJump(pagerJump) {
            pagerJump = Number.parseInt(pagerJump);
            if (
                pagerJump > 0 &&
                pagerJump <= Math.ceil(this.pager.total / this.pager.size)
            ) {
                // console.log(typeof(pagerJump));
                this.handleCurrentChange(pagerJump);
            }
        },
    },
};
</script>

<style lang="scss" scope>
.customer-project {
    width: 100%;
    height: auto;
    display: flex;
    padding: 0 20px 20px;
    .customer-sub-customer {
        // width: 280px;
        width: 410px;
        padding: 20px;
        margin-right: 28px;
        background-color: #f8f8f8;
        position: relative;
        .sub-customer-list {
            .sub-customer-item {
                color: #333;
                line-height: 36px;
                cursor: pointer;
                &.active {
                    color: $primary;
                }
                &:hover {
                    color: $primary;
                }
            }
        }
        .department-link {
            position: absolute;
            top: 20px;
            right: 20px;
            line-height: 36px;
            z-index: 1;
        }
    }

    .project-wrap {
        flex: 1;
        // max-width: 892px;
        height: auto;
        width: calc(100% - 430px);

        .project-list-pagination.el-pagination {
            margin: 40px 0 20px 0;
            padding: 0;
            font-weight: normal;
            .btn-next,
            .btn-prev {
                height: 38px;
                border: 1px solid;
            }
            .btn-prev {
                border-right: none;
                border-radius: 4px 0 0 4px;
            }
            .btn-next {
                border-left: none;
                border-radius: 0 4px 4px 0;
            }
            .el-pagination__sizes .el-input {
                width: 87px;
                .el-input__inner {
                    color: #666;
                    .el-select__caret {
                        color: #999;
                    }
                }
            }
            .el-pager {
                border-top: 1px solid;
                border-bottom: 1px solid;
                li {
                    min-width: 36px;
                    height: 36px;
                    line-height: 36px;
                    font-size: 14px;
                }
            }
            .btn-next,
            .btn-prev,
            .el-pager {
                border-color: #dddddd;
            }
            .el-input__inner,
            .el-pagination__total,
            .el-pagination__jump {
                height: 38px;
                line-height: 38px;
                font-size: 14px;
            }
            .pagination-text {
                color: #999;
                span {
                    height: 38px;
                    line-height: 38px;
                    font-size: 14px;
                    .el-input {
                        width: 48px;
                        margin: 0 5px;
                        &__inner {
                            height: 38px;
                            line-height: 38px;
                            padding: 0 5px;
                        }
                    }
                    &:nth-of-type(2) {
                        margin-left: 5px;
                        color: $primary;
                        cursor: pointer;
                    }
                }
            }
            .el-pagination__total,
            .el-pagination__sizes,
            .pagination-text {
                float: right;
            }
        }
        .empty {
            min-height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .empty-img {
                display: inline-block;
                background: url("~@src/assets/images/home/icon_no_data.svg")
                    no-repeat;
                background-size: contain;
                width: 170px;
                height: 160px;
            }
            .empty-data {
                font-size: 14px;
                margin-bottom: 0px;
            }
        }
    }
}
</style>
