<template>
  <div class="customer-job-list">
    <template v-if="jobList.length > 0">
      <div
        class="customer-job-item"
        v-for="(job, index) in jobList"
        :key="index">
        <div class="job-item-header">
          <!-- <a
            target="_blank"
            :title="job.name"
            class="job-title-link text-ellipsis"
            :href="`/Headhunting/MyCompany.html#/Job/${job.id}`">
            {{job.name}}
          </a> -->
          <a
            target="_blank"
            :title="job.name"
            class="job-title-link text-ellipsis"
            :href="`/#/Job/${job.id}`">
            {{job.name}}
          </a>
          <span class="job-location text-ellipsis">
            [{{job.location}}]
          </span>
          <div class="job-detail-tags">
            <el-tooltip
              v-for="item in job.activityTags"
              :key="item.id"
              effect="light"
              placement="right"
              :disabled="!item.description"
              v-show="item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'"
              popper-class="job-tag-description">
              <div slot="content" v-html="item.description"></div>
              <!-- 
                "FirstOrderActivityJob": {
                  "id": "FirstOrderActivityJob",
                  "theme": "primary",
                  "text": "首",
                  "description": "首个抢单猎头即可获得2元微信红包"
                },
               -->
              <span class="job-detail-tag" :class="`job-tag-${jobActivityTag[item.id].theme}`">{{item.name}}</span>
            </el-tooltip>
            <span class="job-record-icon" title="该职位有职位讲解" v-if="job.hasRecord"></span>
          </div>
        </div>
        <div class="job-item-body">
          <div class="job-intro">
            <div class="job-info">
              <span class="job-info-item">{{job.salary == '0K-0K' ? '面议' : job.salary}}</span>
              <span class="job-info-item">{{job.yearOfExperience}}</span>
              <span class="job-info-item">{{job.degree}}</span>
              <span class="job-info-item">需 {{job.recruitingCount}} 人</span>
            </div>
            <div class="job-promise">
              佣金
              <span class="text-orange job-commission-value">
                  {{job.commissionValue.indexOf('0K-0K') > -1 ? '按比例' : job.commissionValue}}
              </span>
              保证期
              <span class="text-orange job-guarantee-period">{{job.guaranteePeriod}}</span>
              个月
            </div>
            <div
              class="job-firm-commission"
              v-if="job.isCooperativeJob && userInfo.headhuntingFirmType > 0 && ((userInfo.headhuntingFirmType > 2 && job.headhuntingFirmCommissionValue > 0) || (userInfo.headhuntingFirmType < 3 && job.otherFirmCommissionValue > 0))">
              分佣比例
              <span class="text-grey">
                {{userInfo.headhuntingFirmType > 2 ? job.headhuntingFirmCommissionValue + '%' : job.otherFirmCommissionValue + '%'}}
              </span>
              <el-tooltip
                popper-class="commission-rate-text"
                effect="light"
                content="简历提供者，分成比例需要和平台运营确认。"
                placement="right"
                v-if="userInfo.headhuntingFirmType < 3">
                <div class="tip-icon">
                  <font-icon class="default" href="#icon-talent_qd"></font-icon>
                  <font-icon class="hover" href="#icon-talent_ql"></font-icon>
                </div>
              </el-tooltip>
            </div>
          </div>
          <div class="job-number">
            <span title="抢单" v-if="job.isMyJob">
              抢
              <!-- :class="{'job-number-detail': job.orderCount > 0}" -->
              <span
                class="job-number-detail"
                @click="goToJobDetail(job)">
                {{job.orderCount}}
              </span>
            </span>
            <span title="推荐">
              推
              <!-- :class="{'job-number-detail': job.recommendationStatistic && job.recommendationStatistic.total > 0}" -->
              <span
                class="job-number-detail"
                @click="goToFloatList(job, 'total')">
                {{job.recommendationStatistic ? job.recommendationStatistic.total : 0}}
              </span>
            </span>
            <span title="面试">
              面
              <!-- :class="{'job-number-detail': job.recommendationStatistic && job.recommendationStatistic.interviewCount > 0}" -->
              <span
                class="job-number-detail"
                @click="goToFloatList(job, 'interviewCount')">
                {{job.recommendationStatistic ? job.recommendationStatistic.interviewCount : 0}}
              </span>
            </span>
            <span title="offer">
              offer
              <!-- :class="{'job-number-detail': job.recommendationStatistic && job.recommendationStatistic.offerCount > 0}" -->
              <span
                class="job-number-detail"
                @click="goToFloatList(job, 'offerCount')">
                {{job.recommendationStatistic ? job.recommendationStatistic.offerCount : 0}}
              </span>
            </span>
          </div>
          <div class="job-publish">
            <avatar
              class="user-avatar"
              size="sm"
              :src="job.avatarUrl"
              :userId="job.ownerId"
              :enableCard="true"
              :enableLink="true"
              :shareData="getShareData(job)"
            ></avatar>
            <span class="job-date">
              {{job.createDateTime | dateFilter}} 发布
            </span>
          </div>
          <div class="job-grab-btn-wrapper">
            <el-button
              class="job-grab-btn"
              icon="el-icon-time"
              type="primary"
              :loading="job.grabing"
              :disabled="job.isMyOrder || job.isMyJob"
              @click="grabOrder(job)"
              v-if="job.status === 1">
              {{!job.isMyOrder && !job.isMyJob ? '抢单' : '已抢'}}
            </el-button>
          </div>
        </div>
      </div>
    </template>
    <div class="empty-list" v-else>
      <span class="empty-img"></span>
      <p class="empty-data">暂无数据</p>
    </div>
    <el-pagination
      :current-page="pager.current"
      :page-sizes="[10, 30, 50]"
      :page-size="pager.take"
      class="job-list-pagination"
      layout="prev, pager, next, slot, total, sizes"
      :total="total"
      v-if="total > pager.take"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange">
      <span class="pagination-text">
        <span>
          前往
          <el-input
            v-model="pagerJump"
            @keyup.enter.native="handlePagerJump(pagerJump)"
          ></el-input>
          页
        </span>
        <span @click="handlePagerJump(pagerJump)">
          跳转
        </span>
      </span>
    </el-pagination>

    <!-- 抢单成功后的弹出窗口 -->
    <grab-result-dialog
        ref="grabResultDialog"
        @recommend-btn-callback="handleRecommendBtnCallback"
        @grab-dialog-close="grabDialogClose"
        >
    </grab-result-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import Avatar from '#/component/common/avatar.vue';
import GrabResultDialog from '#/views/job-list/component/grab-result-dialog.vue';
import jobActivityTag from '#/js/config/jobActivityTag.json';
import { job as jobUrl} from '#/js/config/api.json';

export default {
  name: "customerJobList",
  components: {
    Avatar,
    GrabResultDialog,
  },
  props: {
    jobList: {
      type: Array,
      default: () => {
        return []
      }
    },
    total: Number
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
  },
  data() {
    return {
      jobActivityTag: jobActivityTag,
      pager: {
        current: 1,
        take: 10
      },
      pagerJump: 0,
    }
  },
  filters: {
    dateFilter(val) {
      let today = moment().format('YYYY-MM-DD'),
          dateDay = moment(val).format('YYYY-MM-DD');
      if(today === dateDay) {
        return '今天'
      } else if(moment().subtract(1, 'days').format('YYYY-MM-DD') === dateDay) {
        return '昨天'
      } else {
        return dateDay;
      }
    }
  },
  methods: {
    goToJobDetail(job) {
      if(job.orderCount > 0) {
        // window.open(`/Headhunting/MyCompany.html#/Job/${job.id}`);
        window.open(`/#/Job/${job.id}`);
      } else {
        return false;
      }
    },
    goToFloatList(job, status) {
      if(job.recommendationStatistic && job.recommendationStatistic[status] > 0) {
        // let urlWithParams = `/Headhunting/?companyName=${encodeURI(job.firmShortName)}&jobName=${encodeURI(job.name)}&statusType=recommendation#/floatingList`;
        // window.open(urlWithParams);
        return false;
      } else {
        return false;
      }
    },
    grabOrder(job) {
      this.$set(job, 'grabing', true);
      let token = this.$store.state.verificationToken.verificationToken;
      if (job.jobType === 2) {
        _request({
          method: 'POST',
          url: jobUrl.get_internal_order,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data:{
            jobId: job.id,
            __RequestVerificationToken: token
          },
          throwBusinessError: true
        }).then(()=>{
          this.$set(job, 'grabing', false)
        //   shortTips('抢单成功！');
          this.$set(job, 'isMyOrder', true)

          this.$refs.grabResultDialog.show(job);
          // window.open(`/Headhunting/MyCompany.html?jobName=${encodeURI(job.name)}#/resultRecommend?jobId=${job.id}&fromPage=GrabOrder`);
        //   window.open(`/?jobName=${encodeURI(job.name)}#/resultRecommend?jobId=${job.id}&fromPage=GrabOrder`);
        }).catch(err => {
          this.$set(job, 'grabing', false)
          switch (err.data.code) {
            case "NotFoundFirm":
              this.$confirm('您尚未加入公司，为更好保障您做单的权益，请加入公司。', '提示', {
                confirmButtonText: '加入公司',
                cancelButtonText: '取消'
              }).then(() => {
                window.open(err.data.url);
              }).catch(() => {});
              break;
            case "NotConfirmEmail":
              this.$confirm('您的邮箱尚未验证，为您能及时获取职位信息，请验证。', '提示', {
                confirmButtonText: '验证邮箱',
                cancelButtonText: '取消'
              }).then(() => {
                // window.open("/Headhunting/MyCompany.html#/emailVerify");
                // window.open("/Headhunting/#/personalCenter?isEmailVerify=true");
                window.open("/Headhunting/#/personalCenter?Tab=myAdvantage");
              }).catch(() => {});
              break;
            case "Applying":
              this.$alert('还未能认证您所在的公司，请等待审核通过后再抢单！', {
                confirmButtonText: '我知道了'
              });
              break;
            case "FirmNotVerified":
              if(this.userInfo.firmVerificationStatus == 0) {
                this.$alert('市场职位抢单需要公司的信息被审核验证', '提示', {
                  confirmButtonText: '申请认证',
                  callback: action => {
                    if(action == 'conform') {
                      window.open("/Headhunting/Firm/Verify");
                    }
                  }
                });
              } else if(this.userInfo.firmVerificationStatus == 1) {
                this.$alert('你的公司信息正在审核中，请等待...', {
                  confirmButtonText: '我知道了'
                });
              }
              break;
            default:
              shortTips("系统异常，请稍后重试");
              break;
          }
        });
      } else {
        let isConfirm = job.jobType === 1 ? true : false;
        _request({
          method: 'POST',
          url: jobUrl.get_market_order,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: {
            jobId: job.id,
            confirm: isConfirm,
            __RequestVerificationToken: token
          },
        }).then(()=>{
          this.$set(job, 'grabing', false)
        //   shortTips('抢单成功！');
          this.$set(job, 'isMyOrder', true)

          this.$refs.grabResultDialog.show(job);
          // window.open(`/Headhunting/MyCompany.html?jobName=${encodeURI(job.name)}#/resultRecommend?jobId=${job.id}&fromPage=GrabOrder`);
        //   window.open(`/?jobName=${encodeURI(job.name)}#/resultRecommend?jobId=${job.id}&fromPage=GrabOrder`);
        }).catch(err => {
          this.$set(job, 'grabing', false)
        });
      }
    },
    getShareData(job) {
      return {
        isPublic: job.isCooperativeJob,
        jobId: job.id,
        jobName: job.name,
        firmShortName: job.firmShortName,
        location: job.location,
        yearOfExperience: job.yearOfExperience,
        degree: job.degree,
        salary: job.salary == '0K-0K' ? '面议' : job.salary,
        commissionValue: job.commissionValue && job.commissionValue.indexOf('0K-0K') > -1 ? '按比例' : job.commissionValue,
        creatorId: job.ownerId,
        creatorName: `${job.realName}@${job.nickname}`,
        creatorAvatar: job.avatarUrl,
        isInnerJob: !job.isCooperativeJob
      }
    },
    handleSizeChange(val) {
      this.pager.take = val;
      this.pager.current = 1;
      this.$emit('update-list');
    },
    handleCurrentChange(current) {
      this.pager.current = current;
      this.$emit('update-list');
    },
    handlePagerJump(pagerJump) {
      // if(pagerJump > Math.ceil(this.total/this.pager.size)) {
      //   return;
      // } else {
      //   this.handleCurrentChange(pagerJump);
      // }
      pagerJump = Number.parseInt(pagerJump);
      if (
        pagerJump > 0 &&
        pagerJump <= Math.ceil(this.total / this.pager.take)
      ) {
        this.handleCurrentChange(pagerJump);
      }
    }
  }
}
</script>

<style lang="scss" scope>
  .customer-job-list {
    background-color: #ffffff;
    border-radius: 8px;
    .customer-job-item {
      background-color: #fff;
      padding: 20px;
      border-top: 1px solid #eee;
      .text-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &:hover {
        background-color: #f6f6f6;
      }
      .job-item-header {
        height: 40px;
        line-height: 40px;
        color: #333;
        font-size: 16px;
        display: flex;
        .job-title-link {
          max-width: 400px;
          margin-right: 15px;
        }
        .job-location {
          max-width: 300px;
          margin-right: 10px;
        }
        .job-detail-tags {
          margin-bottom: 0;
          user-select: none;
          overflow: hidden;
          .job-detail-tag {
            line-height: 16px;
            text-align: center;
            font-size: 12px;
            border: 1px solid #b9b9b9;
            padding: 0 6px;
            border-radius: 10px;
            color: #b9b9b9;
            margin-right: 7px;
            &.job-tag-danger{
              border-color: #ff493c;
              color: #ff493c;
            }
            &.job-tag-primary{
              border-color: #38bc9d;
              color: #38bc9d;
            }
            &.job-tag-info{
              border-color: #39f;
              color: #39f;
            }
            &.job-tag-warning{
              border-color: #ff7200;
              color: #ff7200;
            }
          }
          .job-record-icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url("~@src/assets/images/jobList/job-record-icon.png") no-repeat 0 0;
            background-size: contain;
            position: relative;
            top: 5px;
          }
        }
      }
      .job-item-body {
        display: flex;
        justify-content: space-between;
        color: #666;
        line-height: 20px;
        .job-intro {
          width: 320px;
          .job-info {
            margin-bottom: 10px;
            white-space: nowrap;
            .job-info-item {
              border-right: 1px solid #d3d3d3;
              padding-right: 3px;
              margin-right: 5px;
              &:nth-last-of-type(1) {
                border-right: none;
              }
            }
          }
          .job-promise {
            margin-bottom: 10px;
            color: #b9b9b9;
            white-space: nowrap;
            .job-commission-value {
              margin: 0 10px;
            }
            .job-guarantee-period {
              margin-left: 10px;
            }
            .text-orange {
              color: #f39800;
            }
          }
          .job-firm-commission {
            color: #b9b9b9;
            .text-grey {
              color: #666;
            }
          }
        }
        .job-number {
          // min-width: 180px;
          // max-width: 200px;
          min-width: 215px;
          max-width: 300px;
          & > span {
            display: inline-block;
            min-width: 40px;
            color: #b9b9b9;
            .job-number-detail {
            //   cursor: pointer;
              min-width: 20px;
              color: #333;
            //   &:hover {
            //     color: $primary;
            //   }
            }
          }
        }
        .job-publish {
          width: 120px;
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          top: -10px;
        }
        .user-avatar {
          width: 30px;
          height: 30px;
          .avatar-sm {
            width: 28px;
            height: 28px;
          }
        }
        .job-date {
          color: #999;
          margin-top: 10px;
          text-align: center;
        }
        .job-grab-btn-wrapper {
          width: 100px;
          height: 34px;
        }
        .job-grab-btn {
          width: 100%;
          height: 34px;
          line-height: 34px;
        }
      }
    }
    .empty-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      .empty-img {
        display: inline-block;
        background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
        background-size: contain;
        width: 150px;
        height: 150px;
      }
      .empty-data {
        line-height: 20px;
        margin-bottom: 0px;
      }
    }
    .job-list-pagination.el-pagination {
      margin: 40px 0 20px 0;
      padding: 0;
      font-weight: normal;
      .btn-next, .btn-prev {
        height: 38px;
        border: 1px solid;
      }
      .btn-prev {
        border-right: none;
        border-radius: 4px 0 0 4px;
      }
      .btn-next {
        border-left: none;
        border-radius: 0 4px 4px 0;
      }
      .el-pagination__sizes .el-input{
        width: 87px;
        .el-input__inner {
          color: #666;
          .el-select__caret{
            color: #999;
          }
        }
      }
      .el-pager {
        border-top: 1px solid;
        border-bottom: 1px solid;
        li {
          min-width: 36px;
          height: 36px;
          line-height: 36px;
          font-size: 14px;
        }
      }
      .btn-next, .btn-prev, .el-pager {
        border-color: #DDDDDD;
      }
      .el-input__inner, .el-pagination__total, .el-pagination__jump {
        height: 38px;
        line-height: 38px;
        font-size: 14px;
      }
      .pagination-text{
        color: #999;
        span{
          height: 38px;
          line-height: 38px;
          font-size: 14px;
          .el-input{
            width: 48px;
            margin: 0 5px;
            &__inner{
              height: 38px;
              line-height: 38px;
              padding: 0 5px;
            }
          }
          &:nth-of-type(2){
            margin-left: 5px;
            color: $primary;
            cursor: pointer;
          }
        }
      }
      .el-pagination__total,
      .el-pagination__sizes,
      .pagination-text {
        float: right;
      }
    }
  }
</style>